<template>
  <div class="stu_wrap">
    <div class="workContent">
      <div class="container">
        <div class="center-board">
          <el-scrollbar class="center-scrollbar">
            <el-row class="center-board-row" :gutter="formConf.gutter">
              <el-form :size="formConf.size" :label-position="formConf.labelPosition" :disabled="formConf.disabled"
                :label-width="formConf.labelWidth + 'px'">
                <draggable class="drawing-board" :list="drawingList" :animation="340" group="componentsGroup"
                  :options="dragOptions">
                  <draggable-item2 v-for="(item, index) in drawingList" :key="item.renderKey"
                    :drawing-list="drawingList" :current-item="item" :index="index" :active-id="activeId"
                    :form-conf="formConf" />
                  <div class="fujian" v-for="(item, index) in fujianList" :key="'q' + index">
                    <div style="margin-left:20px;margin-bottom:10px">
                      {{ item.title }}
                    </div>
                    <div class="file" v-for="(items, index) in item.filelist" :key="'w' + index">
                      <i class="el-icon-tickets"></i>
                      <a :href="imgurl + items.id" target="_blank" style="margin-left:10px">{{ items.name }}</a>
                    </div>
                    <el-image v-for="(items, index) in item.imglist" :key="'e' + index"
                      style="width: 200px;height:150px;margin:0 20px 20px 0;border-radius:5px;margin-left:20px"
                      :src="imgurl + items.id" :preview-src-list="item.srclist">
                    </el-image>
                    <video class="fujian_item" v-for="(items, index) in item.videolist" :key="'r' + index"
                      :src="imgurl + items.id" controls="controls" width="200px" height="150"
                      style="display:block;margin-left:20px">
                      您的浏览器不支持播放该视频！
                    </video>
                    <audio class="fujian_item" style="display:block;margin-top:10px"
                      v-for="(items, index) in item.audiolist" :key="index" controls>
                      <source :src="imgurl + items.id" type="audio/mpeg" />
                      如果该元素不被浏览器支持，则本段文本被显示。
                    </audio>
                  </div>
                </draggable>
              </el-form>
            </el-row>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import draggable from "vuedraggable";
  import {
    debounce
  } from "throttle-debounce";
  import {
    saveAs
  } from "file-saver";
  import ClipboardJS from "clipboard";
  import render from "@/components/render/render";
  import JsonDrawer from "./JsonDrawer";
  import RightPanel from "./RightPanel";
  import {
    inputComponents,
    selectComponents,
    layoutComponents,
    formConf
  } from "@/components/generator/config_view";
  import {
    exportDefault,
    beautifierConf,
    isNumberStr,
    titleCase,
    deepClone
  } from "@/utils/index";
  import {
    makeUpHtml,
    vueTemplate,
    vueScript,
    cssStyle
  } from "@/components/generator/html";
  import {
    makeUpJs
  } from "@/components/generator/js";
  import {
    makeUpCss
  } from "@/components/generator/css";
  import drawingDefalut from "@/components/generator/drawingDefalut";
  import logo from "@/assets/logo.png";
  import CodeTypeDialog from "./CodeTypeDialog";
  import DraggableItem from "./DraggableItem2";
  import DraggableItem2 from "./DraggableItem4";
  import {
    getDrawingList,
    saveDrawingList,
    getIdGlobal,
    saveIdGlobal,
    getFormConf
  } from "@/utils/db";
  import loadBeautifier from "@/utils/loadBeautifier";

  let beautifier;
  const emptyActiveData = {
    style: {},
    autosize: {}
  };
  let oldActiveId;
  let tempActiveData;
  const drawingListInDB = getDrawingList();
  const formConfInDB = getFormConf();
  const idGlobal = getIdGlobal();
  export default {
    components: {
      draggable,
      render,
      JsonDrawer,
      RightPanel,
      CodeTypeDialog,
      DraggableItem,
      DraggableItem2
    },
    props: {
      currentWork: Object
    },
    data() {
      return {
        imgurl: this.downloadURL,
        imgUrl: require("../../assets/images/home/avatar.png"),
        UploadPicture: false, //上传图片
        Uploadname: "",
        kejianlist: [],
        dragOptions: {
          sort: false, //定义生成的表单是否可以拖拽
          disabled: true,
        },
        logo,
        idGlobal,
        formConf,
        inputComponents,
        selectComponents,
        // layoutComponents,
        labelWidth: 100,
        drawingList: [],
        drawingData: {},
        activeId: drawingDefalut[0].formId,
        drawerVisible: false,
        formData: {},
        dialogVisible: false,
        jsonDrawerVisible: false,
        generateConf: null,
        showFileName: false,
        activeData: drawingDefalut[0],
        saveDrawingListDebounce: debounce(340, saveDrawingList),
        saveIdGlobalDebounce: debounce(340, saveIdGlobal),
        explain: "", //自定义的作业说明
        showExplain: true, //默认展开说明
        activeName: "first",
        powerVal: null, //评分（针对于每套问卷）
        workAttr: {}, //作业属性
        workTheme: "", //作业主题
        userData: "", //用户答题数据
        userInfo: "", //学员信息
        commentInfo: "", //评论信息
        subjectiveInfo: [], //主观题批改信息
        powers: "", //二级能力标准
        imgurl: this.downloadURL,
        imgUrl: 'this.src="' + require("../../assets/images/home/avatar.png") + '"',
        fujianList: [], //附件题数组
        videoTypes: [
          "mp4",
          "3gp",
          "m4v",
          "mkv",
        ],
        audioTypes: ["wav", "mp3", "aac", ],
        picTypes: ["bmp", "gif", "png", "jpg", "jpeg"],
        fileTypes: ["doc", "docx", "ppt", "pptx", "xls", "xlsx", "pdf", "txt", "wps", "zip", "rar"],
      };
    },
    watch: {
      // eslint-disable-next-line func-names
      "activeData.__config__.label": function (val, oldVal) {
        if (
          this.activeData.placeholder === undefined ||
          !this.activeData.__config__.tag ||
          oldActiveId !== this.activeId
        ) {
          return;
        }
        this.activeData.placeholder =
          this.activeData.placeholder.replace(oldVal, "") + val;
      },
      activeId: {
        handler(val) {
          oldActiveId = val;
        },
        immediate: true
      },
      // drawingList: {
      //   handler(val) {
      //     this.saveDrawingListDebounce(val);
      //     if (val.length === 0) this.idGlobal = 100;
      //   },
      //   deep: true
      // },
      idGlobal: {
        handler(val) {
          this.saveIdGlobalDebounce(val);
        },
        immediate: true
      },
      currentWork(newVal) {
        // console.log(newVal);
        this.fujianList = [];
        this.getworkinfo()
      }
    },
    mounted() {
      // console.log(this.currentWork)
      setTimeout(() => {
        this.getworkinfo()
      })
    },
    methods: {
      //查看当前问卷信息
      getworkinfo() {
        this.$Api.Form.checkForm(this.currentWork.userId, this.currentWork.workId)
          .then(res => {
            // console.log('作业详情', res);
            //获取问卷题干
            let content = JSON.parse(res.data.qcontent);
            //取出作业说明
            content.map(item => {
              //修改表单默认值类型，不然会报错
              if (item.__config__.tag == "el-checkbox-group") {
                item.__config__.defaultValue = [];
              } else {
                item.__config__.defaultValue = "";
              }
              //将表单改为只读或者禁用状态
              if (item.__config__.tag == "el-input") {
                item.readonly = true;
              } else {
                item.disabled = true;
              }
            });
            //将作业说明过滤出去只显示问卷内容
            content = content.filter(item => {
              return item.__config__.tag != "tinymce";
            });
            //获取学员答案
            let resultlist = JSON.parse(res.data.content);
            // console.log("学员答案", resultlist);
            //将答案赋值给对应的题干
            for (let i = 0; i < content.length; i++) {
              for (let j = 0; j < resultlist.length; j++) {
                if (content[i].__vModel__ == resultlist[j].id) {
                  content[i].__config__.defaultValue = resultlist[j].result
                  resultlist.splice(j, 1)
                  break;
                }
              }
            }
            //操作附件题
            // console.log('content', content)
            let drawlist = []
            content.map(item => {
              if (item.__config__.tag == "el-upload") {
                let obj = {};
                obj.srclist = [];
                obj.title = item.__config__.label;
                obj.id = item.__vModel__;
                obj.imglist = item.__config__.defaultValue.filter(item => {
                  return this.picTypes.indexOf(item.type.toLowerCase()) != -1;
                });
                obj.videolist = item.__config__.defaultValue.filter(item => {
                  return this.videoTypes.indexOf(item.type.toLowerCase()) != -1;
                });
                obj.audiolist = item.__config__.defaultValue.filter(item => {
                  return this.audioTypes.indexOf(item.type.toLowerCase()) != -1;
                });
                obj.filelist = item.__config__.defaultValue.filter(item => {
                  return this.videoTypes.indexOf(item.type.toLowerCase()) == -1 && this.picTypes.indexOf(
                    item.type
                    .toLowerCase()) == -1 && this.audioTypes.indexOf(item.type.toLowerCase()) == -1;
                });
                obj.imglist.map(item => {
                  obj.srclist.push(this.imgurl + item.id);
                });
                this.fujianList.push(obj);
              } else {
                drawlist.push(item)
                localStorage.setItem("drawingItems", JSON.stringify(drawlist));
                this.drawingList = JSON.parse(localStorage.getItem("drawingItems"));
              }
            });

            // return content;
            // let drawlist = content.filter(item=>{
            //     return item.
            // })

          })
          .catch(err => {
            console.log('获取失败', err);
            localStorage.removeItem('drawingItems')
          })

      }
    }
  };
</script>

<style lang="less" scoped>
  .stu_wrap {
    .line {
      width: 100%;
      height: 1px;
      background: #f2f2f2;
    }

    .commentContent {
      padding: 14px 0 10px;

      .hascomment {
        margin-bottom: 15px;
      }

      .teainfo {
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 19px;
        }

        span {
          font-size: 12px;
          color: #666666;
        }
      }

      .teacomment {
        margin-left: 50px;
        margin-top: 13px;
      }
    }

    .workContent {
      .container {
        width: 100%;
        background: #ffffff;

        .center-board {
          margin: 0;

          .center-scrollbar {
            border: 0;
          }

          .fujian {
            .file {
              padding-left: 10px;
              margin: 10px 20px;
              height: 50px;
              display: flex;
              align-items: center;
              background: #EBEEF5;
              border-radius: 3px;
              width: 50%;

            }
          }
        }
      }
    }
  }

  @media screen and (min-width:1250px) {
    .stu_wrap>span:first-child {
      font-size: 14px;
    }

    .stu_wrap {
      .commentContent {
        padding: 17px 0 0px;

        .hascomment {
          margin-bottom: 20px;
        }

        .teainfo {
          img {
            width: 36px;
            height: 36px;
            margin-right: 23px;
          }

          span {
            font-size: 14px;
          }
        }

        .teacomment {
          margin-left: 59px;
          margin-top: 17px;
        }
      }
    }
  }
</style>